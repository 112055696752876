import React from 'react'
import { Link } from 'gatsby'

import SEO from 'components/seo'
import { Deco, default as Layout, Header, MainWithMenu, Section } from '../../components/layout'
import { LinkArrowIcon, LogoVerticalIcon } from '../../components/icons'
import { GalleryComponent, PositionIcon } from '../../components/pages/CareersPage'
import { Nav } from '../../components/nav'
import { BackgroundWithGrid } from '../../components/layout'
import { LdJsonLogo, HeaderLink } from '../../components/semanticdata'
import Analytics from '../../components/analytics'

const CareersPage = () => (
  <Layout className="bg-primary-1 text-primary-3">
    <Analytics />
    <SEO title="Careers" pageUrl="/careers/" cardImage="/img/tumbacardjobs1.png" description="Career, carrier, curry. Just words. What really matters? Your talent and passion. Maybe we can play together." />
    <LdJsonLogo />
    <MainWithMenu className="grid">
      <Header className="z-20 pt-6">
        <HeaderLink className="text-primary-2" />
        <div className="pt-12 pb-32 text-left leading-tight text-shadow">
          <p className="pt-4 pb-4 text-4xl lg:text-5xl">
            Career, carrier, curry. Just words.
            <br />
            What really matters? Your talent and passion. Maybe we can play together.
          </p>
          <a className="mt-6 flex items-baseline text-primary-2" href="#positions">
            <span className="uppercase font-normal tracking-normal text-sm">see open positions</span>
            <LinkArrowIcon className="inline ml-2 w-3 transform rotate-90 stroke-current" />
          </a>
        </div>
      </Header>
      <Deco before after decoType={2} gridRow={2} className="z-10 bg-primary-2" />
      <Section className="z-20 mb-10" gridRow={2}>
        <div className="mt-6 flex items-baseline">
          <LogoVerticalIcon className="inline h-10" />
          <h2 className="p-4 text-3xl lg:text-4xl text-primary-1">Our reality</h2>
        </div>
        <div className="grid gap-12 lg:gap-20 grid-cols-2 lg:grid-cols-3">
          <div className="col-span-2 h-56 sm:h-72 lg:h-full">
            <iframe title='The Ride' className='w-full h-full'
                    src="https://www.youtube.com/embed/a1uOyMezyi0?autoplay=0"
            />
          </div>
          <div className="col-span-2 lg:col-span-1 grid gap-4 grid-rows-3 grid-cols-5 lg:grid-rows-5 lg:grid-cols-3">
            <GalleryComponent imageCount={15} className="object-cover h-full w-full" />
          </div>
        </div>
      </Section>
      <BackgroundWithGrid image="careers-office" gridRow={3} />
      <Deco style={{ background: 'rgba(0, 0, 0, 0.4)' }} gridRow={3} className="z-0" />
      <Section className="z-20 mt-40 mb-32" gridRow={3}>
        <h2 className="pt-4 pb-16 text-4xl lg:text-5xl text-primary-3 text-shadow">Office perks & activities</h2>
        <div className="grid gap-10 lg:gap-12 grid-cols-2 lg:grid-cols-3 text-2xl lg:text-3xl text-primary-1">
          <span>
            Set your work <br /> schedule
          </span>
          <span>
            Office in the <br /> Woods
          </span>
          <span>
            Board <br /> Games Nights
          </span>
          <span>
            Referral <br /> bonus
          </span>
          <span>
            Work <br /> from home
          </span>
          <span>
            Superb <br /> Health Insurance
          </span>
          <span>
            Sport
            <br /> & charity events
          </span>
          <span>
            Bday <br /> bonus
          </span>
          <span>
            Salary = <br /> Needs & Wishes
          </span>
          <span>
            Sports <br /> Membership
          </span>
          <span>
            Tech <br /> meetups
          </span>
          <span>
            Healthy Snacks <br /> in the office
          </span>
        </div>
      </Section>
      <Section gridRow={4}>
        <div className="pt-12 pb-32 text-left leading-tight text-shadow">
          <p className="pt-4 pb-4 text-4xl lg:text-5xl">We see code as a craft. We share the passion to co-create with clients & conquer tech challenges that teach skill and mastery.</p>
          <Link to="/manifesto/" className="mt-6 flex items-center text-primary-2">
            <span className="uppercase font-normal tracking-normal text-sm">manifesto</span>
            <LinkArrowIcon className="inline ml-2 w-3 stroke-current" />
          </Link>
        </div>
      </Section>
      <Section id="positions" className="pt-12 pb-32" gridRow={5}>
        <h1 className="pt-4 pb-12 text-4xl lg:text-5xl text-shadow">Open positions</h1>
        <div className="grid text-3xl lg:text-4xl text-primary-1 gap-12" style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(12ch, 1fr))' }}>
          {/*Lead job ads*/}
          {/*<div>*/}
          {/*  <PositionIcon className="h-24 text-primary-3 fill-current" />*/}
          {/*  <h2 className="pt-4 text-3xl lg:text-4xl text-primary-3 text-shadow">Hands-on Team Lead</h2>*/}
          {/*  <Link to="/careers/teamlead/" className="pt-2 flex items-center text-primary-2">*/}
          {/*    <span className="uppercase font-normal tracking-normal text-sm">details and application form</span>*/}
          {/*    <LinkArrowIcon className="inline ml-2 w-3 stroke-current" />*/}
          {/*  </Link>*/}
          {/*</div>*/}
          {/* Mobile dev jobs */}
          <div>
            <PositionIcon className="h-24 text-primary-3 fill-current" />
            <h2 className="pt-4 text-3xl lg:text-4xl text-primary-3 text-shadow">Android Developer</h2>
            <Link to="/careers/android/" className="pt-2 flex items-center text-primary-2">
              <span className="uppercase font-normal tracking-normal text-sm">details and application form</span>
              <LinkArrowIcon className="inline ml-2 w-3 stroke-current" />
            </Link>
          </div>
          <div>
            <PositionIcon className="h-24 text-primary-3 fill-current" />
            <h2 className="pt-4 text-3xl lg:text-4xl text-primary-3 text-shadow">iOS Developer</h2>
            <Link to="/careers/ios/" className="pt-2 flex items-center text-primary-2">
              <span className="uppercase font-normal tracking-normal text-sm">details and application form</span>
              <LinkArrowIcon className="inline ml-2 w-3 stroke-current" />
            </Link>
          </div>
          {/* Java and web dev jobs */}
          {/*<div>*/}
          {/*  <PositionIcon className="h-24 text-primary-3 fill-current" />*/}
          {/*  <h2 className="pt-4 text-3xl lg:text-4xl text-primary-3 text-shadow">Senior Web Frontend Developer</h2>*/}
          {/*  <Link to="/careers/seniorweb/" className="pt-2 flex items-center text-primary-2">*/}
          {/*    <span className="uppercase font-normal tracking-normal text-sm">details and application form</span>*/}
          {/*    <LinkArrowIcon className="inline ml-2 w-3 stroke-current" />*/}
          {/*  </Link>*/}
          {/*</div>*/}
          {/*<div>*/}
          {/*  <PositionIcon className="h-24 text-primary-3 fill-current" />*/}
          {/*  <h2 className="pt-4 text-3xl lg:text-4xl text-primary-3 text-shadow">Senior Web Developer with React</h2>*/}
          {/*  <Link to="/careers/seniorreact/" className="pt-2 flex items-center text-primary-2">*/}
          {/*    <span className="uppercase font-normal tracking-normal text-sm">details and application form</span>*/}
          {/*    <LinkArrowIcon className="inline ml-2 w-3 stroke-current" />*/}
          {/*  </Link>*/}
          {/*</div>*/}
          {/*<div>*/}
          {/*  <PositionIcon className="h-24 text-primary-3 fill-current" />*/}
          {/*  <h2 className="pt-4 text-3xl lg:text-4xl text-primary-3 text-shadow">Senior Java Developer with Spring Boot</h2>*/}
          {/*  <Link to="/careers/seniorjava/" className="pt-2 flex items-center text-primary-2">*/}
          {/*    <span className="uppercase font-normal tracking-normal text-sm">details and application form</span>*/}
          {/*    <LinkArrowIcon className="inline ml-2 w-3 stroke-current" />*/}
          {/*  </Link>*/}
          {/*</div>*/}
          {/*<div>*/}
          {/*  <PositionIcon className="h-24 text-primary-3 fill-current" />*/}
          {/*  <h2 className="pt-4 text-3xl lg:text-4xl text-primary-3 text-shadow">Java Developer with Spring Boot</h2>*/}
          {/*  <Link to="/careers/java/" className="pt-2 flex items-center text-primary-2">*/}
          {/*    <span className="uppercase font-normal tracking-normal text-sm">details and application form</span>*/}
          {/*    <LinkArrowIcon className="inline ml-2 w-3 stroke-current" />*/}
          {/*  </Link>*/}
          {/*</div>*/}
          {/*<div>*/}
          {/*  <PositionIcon className="h-24 text-primary-3 fill-current" />*/}
          {/*  <h2 className="pt-4 text-3xl lg:text-4xl text-primary-3 text-shadow">Full-Stack Java Developer with Spring Boot and React</h2>*/}
          {/*  <Link to="/careers/fullstackjava/" className="pt-2 flex items-center text-primary-2">*/}
          {/*    <span className="uppercase font-normal tracking-normal text-sm">details and application form</span>*/}
          {/*    <LinkArrowIcon className="inline ml-2 w-3 stroke-current" />*/}
          {/*  </Link>*/}
          {/*</div>*/}
          {/* Other jobs */}
          {/*<div>*/}
          {/*  <PositionIcon className="h-24 text-primary-3 fill-current" />*/}
          {/*  <h2 className="pt-4 text-3xl lg:text-4xl text-primary-3 text-shadow">C++ Developer in Automotive</h2>*/}
          {/*  <Link to="/careers/cppauto/" className="pt-2 flex items-center text-primary-2">*/}
          {/*    <span className="uppercase font-normal tracking-normal text-sm">details and application form</span>*/}
          {/*    <LinkArrowIcon className="inline ml-2 w-3 stroke-current" />*/}
          {/*  </Link>*/}
          {/*</div>*/}
          {/*<div>*/}
          {/*  <PositionIcon className="h-24 text-primary-3 fill-current" />*/}
          {/*  <h2 className="pt-4 text-3xl lg:text-4xl text-primary-3 text-shadow">Senior Automation QA Engineer</h2>*/}
          {/*  <Link to="/careers/qa/" className="pt-2 flex items-center text-primary-2">*/}
          {/*    <span className="uppercase font-normal tracking-normal text-sm">details and application form</span>*/}
          {/*    <LinkArrowIcon className="inline ml-2 w-3 stroke-current" />*/}
          {/*  </Link>*/}
          {/*</div>*/}
          {/*<div>*/}
          {/*  <PositionIcon className="h-24 text-primary-3 fill-current" />*/}
          {/*  <h2 className="pt-4 text-3xl lg:text-4xl text-primary-3 text-shadow">Software Development Engineer in Test</h2>*/}
          {/*  <Link to="/careers/sdet/" className="pt-2 flex items-center text-primary-2">*/}
          {/*    <span className="uppercase font-normal tracking-normal text-sm">details and application form</span>*/}
          {/*    <LinkArrowIcon className="inline ml-2 w-3 stroke-current" />*/}
          {/*  </Link>*/}
          {/*</div>*/}
          {/*<div>*/}
          {/*  <PositionIcon className="h-24 text-primary-3 fill-current" />*/}
          {/*  <h2 className="pt-4 text-3xl lg:text-4xl text-primary-3 text-shadow">DevCamp Summer Internship</h2>*/}
          {/*  <Link to="/careers/internship/" className="pt-2 flex items-center text-primary-2">*/}
          {/*    <span className="uppercase font-normal tracking-normal text-sm">details and application form</span>*/}
          {/*    <LinkArrowIcon className="inline ml-2 w-3 stroke-current" />*/}
          {/*  </Link>*/}
          {/*</div>*/}
        </div>
      </Section>
      <Nav gridRow={6} className="z-20 text-primary-2" />
    </MainWithMenu>
  </Layout>
)

export default CareersPage
